import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function Coinster() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Coinster</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          <p>
            I spearheaded the design and development of a mobile crypto
            portfolio app for both Android and iOS platforms. This app provided
            real-time tracking of crypto assets coupled with a secure portfolio
            manager. From UI to UX, graphic design to logo creation, I was
            responsible for every aspect of its design.
          </p>

          <p>
            Although the app remained a private tool for myself and friends and
            didn't reach a wider audience, the project served as a profound
            learning experience in mobile app design and development. It enabled
            me to explore the intricacies of this dynamic field, from emerging
            technologies to user-centric design principles.
          </p>

          <div className="flex gap-5">
            <div className="grow basis-auto">
              <img src={require("../assets/coinster1.png")} alt="" />
            </div>
            <div className="basis-grow">
              <img src={require("../assets/coinster2.png")} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk's attention to detail and commitment to our user experience shone through every aspect of the app."
        }
        quoter={"- Scott, Founder @ AltShiftDev Inc."}
        list={[
          "Mobile App",
          "Wireframes and Prototypes",
          "A/B Testing",
          "UI Designs",
        ]}
      />
    </div>
  );
}

Coinster.componentName = "Coinster";
