import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";

export default function Slider({ before, after, color }) {
  const delimiterIconStyles = {
    width: "70px",
    height: "70px",
    backgroundSize: "unset",
    borderRadius: "0",
    backgroundColor: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    cursor: "col-resize",
    backgroundImage: "url(/images/icon1.svg)",
  };
  return (
    <ReactBeforeSliderComponent
      delimiterIconStyles={delimiterIconStyles}
      delimiterColor={color}
      firstImage={after}
      secondImage={before}
      className="my-4 rounded-lg overflow-hidden "
    />
  );
}
