import { Link } from "react-router-dom";

export default function NoPage() {
  return (
    <div id="portfolio-entry" className="flex flex-col justify-center items-center h-[80vh] gap-3">
      <span className="text-3xl">Huh.</span>
      <span>Looks like this page doesn't exist yet.</span>
      <Link to={'/'} className="underline">Back</Link>
    </div>
  );
}
