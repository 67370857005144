import { Component } from "react";
import { Link, useLocation, useMatches } from "react-router-dom";

export default function Breadcrumb() {
  const location = useLocation();
  // const matches = useMatches();

  return (
    <div className="pb-4 pt-6 sticky top-0 z-50 bg-[#252525]">
      <ul className="flex gap-4 items-center ">
        {location.pathname !== "/" && (
          <>
            <Link
              to={"/"}
              className={`cursor-pointer hover:underline ${
                location.pathname === "/" &&
                " hover:no-underline no-underline cursor-default"
              }`}
            >
              Home
            </Link>
            /
            <div className="hover:no-underline no-underline cursor-default">
              {decodeURI(location.pathname.split('/')[2])}
            </div>
          </>
        )}
      </ul>
    </div>
  );
}
