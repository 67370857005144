import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function LeaseSniper() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Lease Sniper</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          {/* <div className="flex">
            <img src={require("../assets/dextel1.png")} alt="" />
          </div> */}

          <p>
            Creating a digital showcase for Lease Sniper, our team delivered a
            custom CMS integrated with API functions from Black Book Canada,
            allowing LeaseSniper to showcase their extensive inventory of
            premium cars in detail.
          </p>

          <p>
            Lease Sniper approached us to digitally transform their website,
            seeking a modern look to showcase their large inventory of premium
            cars for lease or take-over. We provided them with a fresh design to
            help their website stand out. The website was custom-built in
            WordPress, giving them the ability to easily manage new leases and
            lease transfers on their own. To help Lease Sniper gain the
            attention and results it deserves, we engaged in a comprehensive
            digital marketing campaign, informing their target audience about
            their unique offering.
          </p>

          <div className="flex flex-col gap-5">
            

            <div className="flex rounded-lg">
              <div className="basis-full">
                <img src={require("../assets/lease1.png")} alt="" />
              </div>
            </div>

            <div className="flex gap-5">
              <div className="grow basis-auto">
                <img src={require("../assets/lease2.png")} alt="" />
              </div>
              <div className="basis-grow">
                <img src={require("../assets/lease3.png")} alt="" />
              </div>
            </div>

            <div className="flex rounded-lg">
              <div className="basis-full">
                <img src={require("../assets/lease4.png")} alt="" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk's effort helped us get our showroom onto the website and into the 21st century. Cheers!"
        }
        quoter={"- Head of Sales @ Lease Sniper"}
        list={[
          "API Integration",
          "Inventory MGMT",
          "Wireframes & Prototypes",
        ]}
      />
    </div>
  );
}
