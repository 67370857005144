import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function MomsBeyond() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Moms Beyond</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          <video
            className="rounded-xl overflow-hidden"
            src={require("../assets/mom.mp4")}
            autoplay=""
            loop="true"
            muted=""
            playsinline=""
          ></video>

          <p>
            Crafting a digital haven for moms worldwide, Moms Beyond offers a
            unique platform for connection and sharing.
          </p>

          <div className="flex gap-5">
            <div className="basis-8/12 [&>*]:mb-8">
              <p>
                Our collaboration with Moms Beyond focused on the development of
                a mobile app tailored to their vision. The Moms Beyond app
                provides mothers with a seamless interface to connect and
                interact with like-minded individuals from across North America
                and beyond.
              </p>
              <p>
                From sharing experiences and tips to posting special
                moments, the app fosters a supportive community where mothers
                can bond over their shared journey through motherhood.
              </p>
            </div>
            <div className="basis-4/12">
              <img src={require("../assets/mom2.png")} alt="" />
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <div className="grow basis-auto">
              <img src={require("../assets/mom3.png")} alt="" />
            </div>
            <div className="basis-grow">
              <img src={require("../assets/mom4.png")} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk's expertise in mobile app development helped us create a welcoming digital space."
        }
        quoter={"- Head Mom @ MomsBeyond Inc."}
        list={[
          "Mobile App",
          "UI Designs",
          "Branding",
          "Wireframes and Prototypes",
        ]}
      />
    </div>
  );
}
