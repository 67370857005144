import React from "react";
import { Link } from "react-router-dom";
import arr from "../work.json";

const Tiles = () => {
  return (
    <div className="flex flex-wrap m-auto [&_*]:rounded-md max-w-[1280px]">
      {arr.map((x) => (
        <div
          key={x.id}
          className="flex  basis-full p-2 sm:basis-1/2 md:basis-1/3 lg:basis-1/4 overflow-hidden"
          style={{ height: 250 }}
        >
            <Link
              to={`/work/${x.id}`}
              className="overflow-hidden relative w-full [&_.background]:hover:scale-105 "
            >
              <img
                className={`w-full h-full object-cover z-10 hover:transition-all hover:ease-in-out hover:duration-300 transition duration-300 ${x.videoUrl === '' ? 'background' : '' } ${x.imgClasses}`}
                src={require(`../assets/${x.imgUrl}`)}
                alt={x.id}
              />
              {x.videoUrl !== "" && (
                <video
                  className="object-cover w-full h-full background hover:transition-all hover:ease-in-out hover:duration-300 transition duration-300"
                  src={require(`../assets/${x.videoUrl}`)}
                  loop
                  autoPlay
                  muted
                  playsInline
                />
              )}
              <div className="tile-background flex px-6 z-20 py-4 transition-opacity duration-150 ease-linear opacity-0 hover:opacity-100 absolute top-0 right-0 bottom-0 left-0">
                <span className="flex items-end text-md">{x.id}</span>
              </div>
            </Link>
        </div>
      ))}
    </div>
  );
};

export default Tiles;
