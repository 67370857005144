import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="flex w-full py-4 px-8 justify-between items-center z-[100] relative lets-see-paul-allens-shadow">
      <Link to="/">
        <h1 className="">Kirk Ross</h1></Link>
      <div className="flex gap-6">
        <Link to="/contact">
          <span>Contact</span>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
