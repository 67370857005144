import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function Crownring() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>CrownRing</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">

          <p>
            Crafting an online presence fit for royalty, Crown Ring's website
            and custom B2B portal offer a seamless experience for retailers and
            customers alike.
          </p>

          <p>
            Our collaboration with CrownRing resulted in a comprehensive B2B
            portal tailored to their exact specifications. Designed with
            specific modules around CrownRing's products, this industry-leading
            platform offers retailers a seamless ordering experience, complete
            with live price lists, an anniversary ring builder, and advanced
            retail features.
          </p>

          <p>
            In addition to the custom portal, we crafted a fresh new website
            with a user-friendly design, allowing visitors to effortlessly
            explore CrownRing's latest offerings. From browsing collections to
            learning about the brand's story, the website reflects CrownRing's
            commitment to excellence in every detail.
          </p>

          <div className="flex">
            <img src={require("../assets/cr1.png")} alt="" />
          </div>
          <div className="flex">
            <img src={require("../assets/cr2.png")} alt="" />
          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk's design expertise revamped our online platform, allowing us to better showcase our jewelry and connect with our customers."
        }
        quoter={"- Haimy, President @ CrownRing Inc."}
        list={[
          "e-Commerce",
          "B2B Platform",
          "Graphic Design",
          "Digital Asset Management",
        ]}
      />
    </div>
  );
}
