import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function LesLoftsCadbury() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Les Lofts Cadbury</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          {/* <div className="flex">
            <img src={require("../assets/dextel1.png")} alt="" />
          </div> */}

          <p>
            Crafting a digital showcase for Les Lofts Cadbury, our team
            delivered a fully redesigned website that elegantly presents their
            commercial lofts to creative businesses.
          </p>

          <p>
            In response to their vision, we crafted a user-friendly, modern
            website that reflects the creativity and uniqueness of Les Lofts
            Cadbury. Our team worked closely with them to develop a content
            strategy that effectively communicates their distinctive offerings
            to potential tenants. Leveraging WordPress, we custom designed the
            website to provide maximum flexibility, empowering Les Lofts Cadbury
            to independently manage their available units and facilitate visit
            scheduling for prospective tenants with ease.
          </p>

          <div className="flex">
            <img src={require("../assets/cad1.png")} alt="" />
          </div>
          <div className="flex">
            <img src={require("../assets/cad2.png")} alt="" />
          </div>
          <div className="flex">
            <img src={require("../assets/cad3.png")} alt="" />
          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk helped us create a digital platform that communicates our unique offering to potential tenants. Thank you!"
        }
        quoter={"- Marketing Manager @ Les Lofts Cadbury"}
        list={[
          "Custom CMS",
          "Wireframes and Prototypes",
          "Digital Content",
          "Branding & Identity",
        ]}
      />
    </div>
  );
}
