export default function Testimonial({ quote, quoter, list }) {
  return (
    <div className="flex flex-col sm:flex-row bg-[#B7B7B7] text-[#252525] items-start sm:justify-center px-8 py-16 gap-28">
      <div className="flex flex-col basis-8/12 gap-12 justify-center">
        <div className="text-3xl">{quote}</div>
        <div>{quoter}</div>
      </div>

      <div className="flex flex-col basis-4/12">
        <div className="font-medium mb-7">Deliverables</div>
        <ul>
          {list.map((x, index) => (
            <li className="mb-2" key={index}>{x}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
