import Breadcrumb from "../components/breadcrumbs";
import Marquee from "react-fast-marquee";
import Testimonial from "../components/testimonial";

export default function Dextel() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Dextel Agency</h1>
        <h2>Senior Product Designer</h2>

        <div className="content [&_*]:mb-8">
          <div className="flex">
            <img className="!mb-0" src={require("../assets/dextel1.png")} alt="" />
          </div>
          <p>
            At Dextel, a leading web agency specializing in e-commerce, I
            immersed myself in the intersection of design and development,
            contributing to a legacy of innovation in user-centric e-commerce
            platforms.
          </p>

          {/* <div className="flex justify-between gap-28">
            <div className="basis-1/2">
              <img src={require("../assets/dextel4.png")} alt="" />
            </div>

            <div className="basis-1/2">
              <img src={require("../assets/dextel5.png")} alt="" />
            </div>
          </div> */}

          <p>
            My role involved leading the design team, spearheading projects from
            conception to execution. I prioritized client collaboration,
            initiating each project with in-depth consultations to align on
            brand identity and objectives. Extensive research and user
            interviews informed our strategies, ensuring every design decision
            enhanced usability.
          </p>

          <div className="flex gap-8">
            <div className="flex flex-col basis-7/12">
              <p>
                Wireframing and prototyping were central to my process.
                Leveraging tools like Figma, the Adobe suite and InVision I was
                able to generate interactive prototypes to bring my designs to
                life and inviting client feedback that refined our vision.
              </p>
              <p>
                Collaboration was key. I worked closely with developers,
                providing detailed design specifications to bridge the gap
                between vision and execution. With a focus on client
                satisfaction and project integrity, I facilitated seamless
                communication, ensuring projects remained on track and within
                scope.
              </p>
            </div>
            <div className="basis-5/12">
              <img src={require("../assets/dextel9.png")} alt="" />
            </div>
          </div>

        </div>
      </div>
      <Marquee direction="left" speed={25} className="my-12 block">
        <img
          className="mr-32"
          src={require("../assets/noamcarver.png")}
          alt=""
        />
        <img
          className="mr-32"
          src={require("../assets/crownring.png")}
          alt=""
        />
        <img className="mr-32" src={require("../assets/aws.png")} alt="" />
        <img
          className="mr-32"
          src={require("../assets/bleueroyale.png")}
          alt=""
        />
        <img className="mr-32" src={require("../assets/avenir.png")} alt="" />
        <img className="mr-32" src={require("../assets/luxer.png")} alt="" />
        <img className="mr-32" src={require("../assets/lofts.png")} alt="" />
        <img
          className="mr-32"
          src={require("../assets/modanessa.png")}
          alt=""
        />
      </Marquee>

      {/* <div className="m-auto px-8 max-w-5xl">
        <div className="content [&>*]:mb-8">
          <div className="flex flex-col gap-5">
            <div className="flex gap-5">
              <div className="grow basis-auto">
                <img src={require("../assets/dextel10.png")} alt="" />
              </div>
              <div className="">
                <img src={require("../assets/dextel11.png")} alt="" />
              </div>
            </div>

            <div className="flex">
              <div className="basis-full">
                <img src={require("../assets/dextel12.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Marquee direction="right" speed={25} className="my-12">
        <img className="mr-32" src={require("../assets/provoke.png")} alt="" />
        <img className="mr-32" src={require("../assets/cadbury.png")} alt="" />
        <img className="mr-32" src={require("../assets/londono.png")} alt="" />
        <img className="mr-32" src={require("../assets/sgellis.png")} alt="" />
        <img className="mr-32" src={require("../assets/rae.png")} alt="" />
        <img className="mr-32" src={require("../assets/capmax.png")} alt="" />
        <img
          className="mr-32"
          src={require("../assets/swisstime.png")}
          alt=""
        />
        <img className="mr-32" src={require("../assets/russ.png")} alt="" />
      </Marquee>

      <Testimonial
        quote={
          "Working with Kirk was a game-changer for our e-commerce projects."
        }
        quoter={"- G. Serruya, Founder @ Dextel Agency"}
        list={[
          "e-Commerce",
          "Marketing",
          "DNS & DevOps",
          "CMS Config. & MGMT",
        ]}
      />
    </div>
  );
}
