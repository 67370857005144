import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div id="portfolio-entry min-h-screen">
      <div className="m-auto px-8 max-w-5xl">
        <h1 className="pt-16">Let’s work together. </h1>
        <h2></h2>

        <div className="flex flex-col justify-center items-center [&>*]:mb-8">
          
          <div className="flex flex-col lg:flex-row gap-6 w-full">
            <div className="flex flex-col items-center justify-between w-full h-[200px] bg-[#e0e0e0] p-6 rounded-md">
              <img
                className="h-[90px] my-4"
                src={require("../assets/icon1.png")}
                alt=""
              />
              <span className="text-[#252525] text-lg sm:text-2xl whitespace-nowrap">UI/UX Design</span>
            </div>

            <div className="flex flex-col items-center justify-between w-full h-[200px] bg-[#e0e0e0] p-6 rounded-md">
              <img
                className="h-[90px] my-4"
                src={require("../assets/icon2.png")}
                alt=""
              />
              <span className="text-[#252525] text-lg sm:text-2xl whitespace-nowrap">Branding & Identity</span>
            </div>

            <div className="flex flex-col items-center justify-between w-full h-[200px] bg-[#e0e0e0] p-6 rounded-md">
              <img
                className="h-[90px] my-4"
                src={require("../assets/icon3.png")}
                alt=""
              />
              <span className="text-[#252525] text-lg sm:text-2xl whitespace-nowrap">Web & Mobil Apps</span>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <span>and more...</span>
            <span className="text-xs mt-4">
              (including front-end development like this portfolio)
            </span>
          </div>
        </div>
        <div className="content [&>*]:mb-8">
          <h3 className="font-bold !mb-2">Email</h3>
          <p>kirk@altshiftdev.com</p>
          <div className="w-[20%]">
            <hr className="border-white" />
          </div>
          <p>
            If you think I'd be a good fit for your project, please reach
            out directly.
          </p>
        </div>
      </div>
    </div>
  );
}
