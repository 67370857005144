import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function Johns() {
  return (
    <div id="portfolio-entry">

      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Johns Wholesale Jewelry</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          <video
            className="rounded-xl overflow-hidden"
            src={require("../assets/john.mp4")}
            autoplay=""
            loop="true"
            muted=""
            playsinline=""
          ></video>

          <p>
            Established in 1981, family-run and operated John’s Wholesale
            Jewelry has been providing clients with exceptional service and the
            highest quality products, carefully selected to ensure that they
            have the latest and the greatest in up to date styles and trends.
            John’s Wholesale Jewelry keeps a large inventory of all of the
            classic styles and basic jewelry that clients have come to expect
            and love.
          </p>

          <p>
            We started with recreating their logo with a sleek, elegant, &
            minimalist design that conveys an image of luxury without appearing
            too flashy or busy. We then got to work designing a fresh website
            with a classy modern layout and structure. We designed a custom
            Magento powered online store that allows them to easily organize and
            showcase their large inventory of classic styles and gives them full
            B2B management, including taking orders from existing customers and
            allowing new customers to create accounts on the website and place
            orders.
          </p>

          <div className="flex flex-col gap-5">
            <div className="flex gap-5">
              <div className="grow basis-auto">
                <img src={require("../assets/john-1.png")} alt="" />
              </div>
              <div className="basis-grow">
                <img src={require("../assets/john-2.png")} alt="" />
              </div>
            </div>

            <div className="flex rounded-lg">
              <div className="basis-full">
                <img src={require("../assets/john-3.png")} alt="" />
              </div>
            </div>

            <div className="flex rounded-lg">
              <div className="basis-full">
                <img src={require("../assets/john-4.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial
        quote={
          "The redesign exceeded my expectations! From the elegant logo to the seamless e-commerce store, Kirk truly elevated our online presence!"
        }
        quoter={"- Patty, Founder @ John's Wholesale Jewelry"}
        list={[
          "e-Commerce",
          "Wireframes and Prototypes",
          "Digital Content & Marketing",
          "CMS Config. & MGMT",
        ]}
      />
    </div>
  );
}
