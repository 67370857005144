import Breadcrumb from "../components/breadcrumbs";
import Slider from "../components/slider";
import Testimonial from "../components/testimonial";

export default function RedSky() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Red Sky GmbH</h1>
        <h2>Lead Product Designer</h2>

        <div className="content [&>*]:mb-8">
          <p>
            As the lead designer for Red Sky's customer-facing container
            tracking software, I spearheaded both the UI and UX aspects, working
            closely with the C-suite to translate high-level product visions
            into seamless user experiences. From inception to launch, I crafted
            our unique style guide and brand identity, defining everything from
            typography to color palettes.
          </p>
          <p>
            Beyond design, I led a successful rebranding initiative,
            orchestrating collaboration with an external marketing firm to
            modernize the company's image. Mentoring junior designers and
            establishing best practices became integral to my role, ensuring a
            cohesive and efficient design pipeline.
          </p>

          <p>
            Taking ownership of the design handoff process, I facilitated
            seamless communication between design and development teams,
            ensuring smooth transitions from concept to implementation. During
            tight deadlines, I even stepped in as a stand-in engineer, designing
            and implementing critical features to keep the project on track.
          </p>

          <div className="rounded-md">
            <Slider
              color="white"
              before={{ imageUrl: require("../assets/before.png") }}
              after={{ imageUrl: require("../assets/after2.png") }}
            />
          </div>

          <div className="rounded-md">
            <Slider
              color="white"
              before={{ imageUrl: require("../assets/contract-before.png") }}
              after={{ imageUrl: require("../assets/contract-after.png") }}
            />
          </div>

          <div className="rounded-md">
            <Slider
              color="white"
              before={{ imageUrl: require("../assets/order-before.png") }}
              after={{ imageUrl: require("../assets/order-after.png") }}
            />
          </div>
          
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk is not just a designer but a driving force behind our success."
        }
        quoter={"- Christian, CTO @ Red Sky GmbH"}
        list={[
          "User Research Reports",
          "Wireframes and Prototypes",
          "Design System",
          "Usability Testing Reports",
          "UI Designs",
        ]}
      />
    </div>
  );
}

RedSky.componentName = "Red Sky";
