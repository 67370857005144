import React from "react";
import { Link, useLocation } from "react-router-dom";
import arr from "../work.json";

const Navarrows = () => {
  const location = useLocation();
  const cleanUrl = decodeURI(location.pathname.split("/")[2]);

  const x = arr.find((x) => x.id === cleanUrl);
  const index = x ? arr.indexOf(x) : undefined;

  const leftArrow = index === 0 ? undefined : index - 1;
  const rightArrow = index + 1 === arr.length ? undefined : index + 1;

  return (
    <div>
      {leftArrow >= 0 && (
        <div>
          <Link
            className=" flex bg-black [&_.the]:hover:left-[32px] fixed left-0 bottom-[10vh] z-40 items-center h-[60px]"
            to={`/work/${arr[leftArrow].id}`}
          >
            <div className="flex justify-center w-8 static h-full z-50 bg-[#666] shadow-xl">
              <svg
                className="aspect-[7/16] h-full p-2 "
                viewBox="0 0 7 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_189_2)">
                  <path
                    d="M6 15L1 8.05185L6 1"
                    stroke="white"
                    stroke-miterlimit="10"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_189_2">
                    <rect width="7" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="flex absolute -left-64 the h-full transition-all duration-[400ms]">
              <img
                className="bg-white h-full w-16 aspect-square object-cover rounded-none"
                src={require(`../assets/${arr[leftArrow].imgUrl}`)}
                alt=""
              />
              <div className="flex text-nowrap items-center h-full px-2 bg-black">
                {arr[leftArrow].id}
              </div>
            </div>
          </Link>
        </div>
      )}

      {rightArrow < arr.length && (
        <div>
          <Link
            className="flex bg-black [&_.the]:hover:right-[92px] fixed right-0 bottom-[10vh] z-40 items-center h-[60px]"
            to={`/work/${arr[rightArrow].id}`}
          >
            <div className="flex w-8 relative h-full z-50 bg-[#666] shadow-xl justify-center">
              <svg
                className="aspect-[7/16] h-full p-2 rotate-180"
                viewBox="0 0 7 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_189_2)">
                  <path
                    d="M6 15L1 8.05185L6 1"
                    stroke="white"
                    stroke-miterlimit="10"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_189_2">
                    <rect width="7" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="flex absolute -right-48 the h-full transition-all duration-[400ms]">
              <div className="flex text-right text-nowrap items-center h-full px-2 bg-black">
                {arr[rightArrow].id}
              </div>
              <img
                className="bg-white h-full w-16 aspect-square object-cover rounded-none"
                src={require(`../assets/${arr[rightArrow].imgUrl}`)}
                alt=""
              />
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navarrows;
