import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function Zenbit() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Zenbit</h1>
        <h2>UI/UX Designer</h2>

        <div className="content [&>*]:mb-8">
          {/* <div className="flex">
            <img src={require("../assets/dextel1.png")} alt="" />
          </div> */}

          <p>
            Zenbit is a web design company allowing users to purchase a
            flat-rate package which is a relatively unique offering in this
            opaque 'a la carte' industry.
          </p>

          <p>
            The business owner decided it was time to update their website but
            were too busy with customers to do so themselves. Since we'd already
            been working together, they contracted me to design and code a clean
            and simple site which could be a showcase of their design &
            technical abilities. The development process required coordinating
            with the business owner, researching industry trends and making sure
            the site's codebase could easily adapt to new requirements that were
            being defined as I went.
          </p>

          <div className="flex gap-6">
            <div className="flex">
              <img src={require("../assets/zen1.png")} alt="" />
            </div>
            <div className="flex">
              <img src={require("../assets/zen2.png")} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Kirk was essential to our 'website 2.0' efforts and provided me with excellent recommendations and advice throughout the process."
        }
        quoter={"- Tanya, Creative Director @ Zenbit Web Design"}
        list={[
          "UI/UX Designs",
          "Branding & Identity",
          "User Research Reports",
          "Wireframes & Prototypes",
          "Usability Testing Reports",
        ]}
      />
    </div>
  );
}
