import Breadcrumb from "../components/breadcrumbs";
import Testimonial from "../components/testimonial";

export default function Noam() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <Breadcrumb />

        <h1>Noam Carver</h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          <p>
            Crafting an online showcase for the iconic Noam Carver, renowned for
            their seamless fusion of classic and contemporary bridal jewelry.
          </p>

          <p>
            We collaborated with Noam Carver to refresh their online presence,
            creating a website that reflects the brand's commitment to quality
            and style. Our design aimed to highlight the beauty of Carver's
            jewelry while ensuring a seamless browsing experience for customers.
            Through custom development, we integrated live inventory updates,
            helping Noam Carver stay connected with their audience. Our
            strategic support aimed to drive online sales and enhance brand
            visibility, aligning with Noam Carver's goals for growth.
          </p>

          <div className="flex">
            <img src={require("../assets/noam2.png")} alt="" />
          </div>
          <div className="flex">
            <img src={require("../assets/noam1.png")} alt="" />
          </div>
        </div>
      </div>

      <Testimonial
        quote={
          "Working with Kirk & Dextel really helped our brand. They redesigned our website, making it easier to show off our jewelry and engage our customers."
        }
        quoter={"- Marketing Lead @ Noam Carver"}
        list={[
          "e-Commerce",
          "CMS Config. & MGMT",
          "Wireframes and Prototypes",
          "Digital Content & Marketing",
        ]}
      />
    </div>
  );
}
