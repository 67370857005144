import "./tailwind.css";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router";

import Navarrows from "./components/navarrows";

import Navbar from "./components/navbar";
import About from "./pages/about";
import Work from "./pages/work";
import Contact from "./pages/contact";
import NoPage from "./pages/noPage";
import RedSky from "./pages/redsky";
import Dextel from "./pages/dextel";
import Zenbit from "./pages/zenbit";
import Johns from "./pages/johns";
import Coinster from "./pages/coinster";
import Noam from "./pages/noam";
import Crownring from "./pages/crownring";
import MomsBeyond from "./pages/momsbeyond";
import LesLoftsCadbury from "./pages/lesloftscadbury";
import LeaseSniper from "./pages/leasesniper";


function App() {
  function Header() {
    return (
      <div>
        <Navarrows></Navarrows>
        <Navbar></Navbar>
      </div>
    );
  }
  function Inner() {
    return (
      <Routes>
        <Route index element={<Work />} />
        <Route path="/about" element={<About />} />
        <Route path="/work/Red Sky GmbH" element={<RedSky />} />
        <Route path="/work/Dextel Agency" element={<Dextel />} />
        <Route path="/work/Zenbit" element={<Zenbit />} />
        <Route path="/work/Johns Wholesale Jewelry" element={<Johns />} />
        <Route path="/work/Coinster" element={<Coinster />} />
        <Route path="/work/Noam Carver" element={<Noam />} />
        <Route path="/work/CrownRing" element={<Crownring />} />
        <Route path="/work/Moms Beyond" element={<MomsBeyond />} />
        <Route path="/work/Les Lofts Cadbury" element={<LesLoftsCadbury />} />
        <Route path="/work/Lease Sniper" element={<LeaseSniper />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<NoPage />} />
      </Routes>
    );
  }

  return (
    <div className="App grid justify-space-around grid-flow-row h-screen">
      <Header></Header>
      <Inner></Inner>
      <footer className="flex p-4 flex-col sm:flex-row justify-between w-screen items-center text-[#a4a4a4] text-sm">
        © {new Date().getFullYear()} Kirk Ross
        <div className="text-center">
          Made in React by ME, code available on my&nbsp;
          <a href="https://github.com/kurck/portfolio" target="_blank">
            <span className="underline">github</span>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
