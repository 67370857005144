import Navbar from "../components/navbar";
import Tiles from "../components/tiles";

export default function About() {
  return (
    <div id="portfolio-entry">
      <div className="m-auto px-8 max-w-5xl">
        <h1>Let’s work together. </h1>
        <h2></h2>

        <div className="content [&>*]:mb-8">
          <p>
            If you think we would make a great fit for your project, please fill
            out the form below or reach out directly. We will get back to you in
            no time, usually between 1-3 business days.
          </p>
        </div>
      </div>
    </div>
  );
}
